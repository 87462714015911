<template>
  <footer class="app-footer">
    <!-- Logo and Description -->
    <div class="first-row">
      <div class="logo-section">
        <img
          src="@/assets/app-bio-logo.png"
          alt="Appbio Logo"
          class="appbio-logo"
          @click="goToHome"
        />
        <p style="margin-bottom: 0px">Biomagnetismo ao seu</p>
        <p>alcance</p>
      </div>

      <!-- Menu Links -->
      <div class="menu-section">
        <ul>
          <li><a href="https://appbio.com.br">Home</a></li>
          <li><a href="#">Encontrar terapeuta</a></li>
          <li>
            <a href="https://appbio.com.br/sou-terapeuta">Sou terapeuta</a>
          </li>
          <li><a @click="redirectToRegister">Criar conta</a></li>
          <li><a @click="redirectToLogin">Entrar</a></li>
        </ul>
      </div>

      <!-- Social Links -->
      <div class="social-section">
        <h4>SOCIAL</h4>
        <a @click="redirectToInstagram">
          <v-icon class="social-icon"> mdi-instagram</v-icon>
        </a>
        <a @click="redirectToFacebook"
          ><v-icon class="social-icon">facebook</v-icon></a
        >
        <a v-if="false" href="#"
          ><v-icon class="social-icon">mdi-linkedin</v-icon></a
        >
      </div>
    </div>

    <!-- Copyright and SSL -->
    <div class="footer-bottom">
      <div class="footer__col-1">
        <span>©2021 Appbio - Todos os direitos reservados.</span>
      </div>
      <div class="footer__col-2">
        <a href="#"
          ><v-icon class="lock-icon">mdi-lock</v-icon> Site seguro SSL</a
        >
      </div>
      <div class="footer__col-3">
        <a
          href="https://s3.amazonaws.com/files.prod.appbio/APPBIO_Termos_e_Condi%C3%A7%C3%B5es_Gerais_de_Uso_e_ou_de_VendaV2.pdf"
          target="_blank"
          >Termos de Uso</a
        >
        <a
          href="https://s3.amazonaws.com/files.prod.appbio/APPBIO_Pol%C3%ADtica_de_PrivacidadeV2.pdf"
          target="_blank"
          >Política de Privacidade</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    redirectToRegister() {
      this.$router.push({
        path: "register",
      });
    },
    redirectToLogin() {
      this.$router.push({
        path: "login",
      });
    },
    redirectToInstagram() {
      window.location.href = "https://www.instagram.com/app.bio/";
    },
    redirectToFacebook() {
      window.location.href = "https://www.facebook.com/app.biomagnetismo";
    },
    goToHome() {
      window.location.href = "https://appbio.com.br";
    },
  },
};
</script>

<style scoped>
.app-footer {
  display: flex;
  flex-direction: column;
  background-color: #265acc;
  color: #fff;
  padding: 0 10%;
  margin-top: 20px;
}

.first-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
}

.appbio-logo {
  width: 160px;
  margin-bottom: 10px;
  cursor: pointer;
}

.logo-section,
.menu-section,
.social-section {
  flex: 1;
}
.menu-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-section {
  text-align: end;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 12px;
}

h4 {
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 5px;
  display: block;
  cursor: pointer;
}

.footer__col-1 {
  flex: 1;
  align-items: center;
  display: flex;
}
.footer__col-2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__col-3 {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.social-section a {
  margin-right: 10px;
}

.social-icon {
  color: #fff;
}
.lock-icon {
  color: #19e06f;
}

@media (max-width: 768px) {
  .first-row {
    flex-direction: column;
  }
  .logo-section {
    text-align: center;
  }
  .menu-section {
    margin-top: 20px;
  }
  .social-section {
    text-align: center;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }
  .footer__col-1 {
    margin-bottom: 20px;
  }
  .footer__col-2 {
    margin-bottom: 20px;
  }
  .footer__col-3 {
    margin-bottom: 20px;
  }
}
</style>
